import { DangerButton } from './form/Buttons';
import { deletePhoto } from '../services/api';

const Image = props => {
	const { preview, fileNameFixed } = props;
	const { url, name } = preview;
	const onClick = () => {
		window.open(url);
	};
	return (
		<>
			<img
				src={url}
				className='col-2 pointer'
				alt={name}
				onClick={() => onClick()}
			/>
			<span>{fileNameFixed}</span>
		</>
	);
};

const Document = props => {
	const { preview, fileNameFixed } = props;
	const { url } = preview;

	return (
		<a href={url} className='btn btn-link' target='_blank' rel='noreferrer'>
			{fileNameFixed}
		</a>
	);
};

const isImage = preview => {
	const ext = preview.ext.toLowerCase();
	switch (ext) {
		case 'jpg':
			return true;
		case 'png':
			return true;
		case 'jpeg':
			return true;
		default:
			return false;
	}
};

const FilePreview = props => {
	const { preview, imgParentClass, paramId, sectionId, getImgUrls } = props;

	const className =
		imgParentClass +
		' border-bottom p-1 d-flex justify-content-between align-items-center';
	const removePhotoFromStorage = fileName => {
		deletePhoto(paramId, sectionId, fileName)
			.then(res => {
				if (res.status !== 200) {
					return alert('an error occurred');
				}
				getImgUrls();
			})
			.catch(err => console.log(err));
	};
	return (
		<div className={className}>
			{isImage(preview) && <Image {...props} />}
			{!isImage(preview) && <Document {...props} />}
			<DangerButton onClick={() => removePhotoFromStorage(preview['name'])}>
				X
			</DangerButton>
		</div>
	);
};

export default FilePreview;
