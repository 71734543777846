const style = {
	position: 'absolute',
	bottom: '25px',
	right: '25px',
	padding: '5px',
	backgroundColor: '#fff',
	borderRadius: '5px',
	fontSize: '12px',
	fontWeight: 'bold'
};

const VersionTag = () => {
	return (
		<div style={style}>
			<span className='px-5'>V1.03 3/30/2022</span>
		</div>
	);
};

export default VersionTag;
