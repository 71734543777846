import { useState } from 'react';
import CheckBox from './form/CheckBox';
import { SuccessButton } from './form/Buttons';
import Section from './Section';
import { genPDF } from '../services/api';

const sections = {
	face: 'Face Sheet',
	vendor: 'Vendor Invoice/Warranty',
	closure: 'Closure Sheet',
	ccCharges: 'CC Charges',
	signOff: 'Sign-off',
	otherEstimates: 'Other Estimates.',
	before: 'Photos Before',
	during: 'Photos During',
	after: 'Photos After',
	otherMisc: 'Other/Misc.'
};

const PdfGenerationForm = props => {
	const { title, subTitle, paramId } = props;
	const [imgPerPg, setImgsPerPg] = useState(9);

	return (
		<>
			<div className='d-flex justify-content-between align-items-start mb-2'>
				<div>
					<h5>{title}</h5>
					<p className='text-muted'>{subTitle}</p>
				</div>
				<div>
					<CheckBox
						checked={imgPerPg === 9}
						onClick={() => setImgsPerPg(9)}
						label='9 Images per page'
					/>
					<CheckBox
						checked={imgPerPg === 4}
						onClick={() => setImgsPerPg(4)}
						label='4 Images per page'
					/>
				</div>
				<SuccessButton
					style={{ fontSize: '1.25rem' }}
					onClick={() => genPDF(paramId, imgPerPg)}
				>
					Final Pay Report
				</SuccessButton>
			</div>
			<div className='row'>
				{Object.keys(sections).map(sectionId => {
					return (
						<Section
							key={sectionId}
							paramId={paramId}
							sectionName={sections[sectionId]}
							sectionId={sectionId}
						/>
					);
				})}
			</div>
		</>
	);
};

export default PdfGenerationForm;
