import React from 'react';

const CheckBox = ({ checked, onClick, label }) => {
	return (
		<div className='form-check'>
			<input
				className='form-check-input'
				type='checkbox'
				checked={checked}
				onClick={onClick}
				onChange={() => {}}
			/>
			<label className='form-check-label'>{label}</label>
		</div>
	);
};

export default CheckBox;
