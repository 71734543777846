import Logo from '../assets/Logo.js';

const Header = () => {
	return (
		<header className='d-flex flex-column flex-md-row align-items-center pb-1 mb-4 border-bottom'>
			<a className='navbar-brand' href='/'>
				<Logo width='45' />
			</a>
			<nav className='d-inline-flex mt-2 mt-md-0 ms-md-auto'>
				<a className='me-3 py-2 text-dark text-decoration-none' href='/'>
					Home
				</a>
			</nav>
		</header>
	);
};

export default Header;
