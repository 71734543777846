const Button = props => {
	const { onClick, children } = props;
	return (
		<button className='btn btn-primary' onClick={onClick}>
			{children}
		</button>
	);
};

export const SuccessButton = props => {
	const { children } = props;
	return (
		<button {...props} className='btn btn-success'>
			{children}
		</button>
	);
};

export const DangerButton = props => {
	const { children } = props;
	return (
		<button {...props} className='btn btn-outline-danger'>
			{children}
		</button>
	);
};

export default Button;
