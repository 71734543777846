import { useDropzone } from 'react-dropzone';

const className = 'py-3 border fw-lighter rounded-pill';

const FileUpload = props => {
	const { accept, onDrop, maxSize } = props;

	const fileTypes =
		accept === 'image/jpeg, image/png, image/jpg' ? 'images' : 'files';

	const { getRootProps, getInputProps } = useDropzone({
		accept,
		onDrop,
		maxSize
	});
	return (
		<div {...getRootProps({ className })}>
			<input {...getInputProps()} />
			<div className='pointer'>Drag n drop or click to upload {fileTypes}</div>
		</div>
	);
};

export default FileUpload;
