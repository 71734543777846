import axios from 'axios';

//* LOCAL TESTING
// export const apiUrl = 'http://localhost:3001';

//* DEVELOPMENT SERVER
// export const apiUrl = 'https://www.pyxis.services';

//* PRODUCTION SERVER
export const apiUrl = 'https://prtdb.pyxis.services';

const instance = axios.create({
	baseURL: apiUrl
});

export const getList = projectId => {
	const data = instance.get(`/list/${projectId}`);
	return data;
};

export const postFile = async (paramId, sectionId) => {
	const { data } = await instance.post(`/files/${paramId}/${sectionId}`);
	// console.log(data);
	let files = [];
	if (data) {
		data.files.forEach(file => {
			files.push({
				url: `${apiUrl}/${paramId}/${sectionId}/${file.name}`,
				ext: file.ext,
				name: file.name
			});
		});
	}

	return { files };
};

export const postPhoto = async form => {
	const headers = { 'Content-Type': 'multipart/form-data' };
	const data = await instance.post(`/photo`, form, headers);
	return data;
};

export const deletePhoto = (paramId, sectionId, fileName) => {
	return instance.delete(`photo/${paramId}/${sectionId}/${fileName}`);
};

export const genPDF = (paramId, imgPerPg) => {
	window.open(`${apiUrl}/downloadPdf/${paramId}/?imgsPerPg=${imgPerPg}`);
};

export default instance;
