import { useState, useEffect } from 'react';
import { getList } from './services/api.js';

import Header from './components/Header';
import Warning from './components/Warning';
import Loading from './components/Loading';
import PdfGenerationForm from './components/PdfGenerationForm.js';
import VersionTag from './components/VersionTag';

import './App.css';

const App = () => {
	const [title, setTitle] = useState('');
	const [subTitle, setSubTitle] = useState('');
	const [paramId, setParamId] = useState('');
	const [warningMessagePreventingUse, setWarning] = useState('');
	useEffect(() => {
		var queryDict = {};
		window.location.search
			.substr(1)
			.split('&')
			.forEach(function (item) {
				queryDict[item.split('=')[0]] = item.split('=')[1];
			});
		setParamId(queryDict.p);
		if (queryDict.p === undefined) {
			setWarning(
				'The url must include p=# for example: https://prtdb.pyxisusa.com/?p=23541'
			);
			return;
		}
		getList(queryDict.p).then(res => {
			if (
				res.data.recordsets === undefined ||
				res.data.recordsets[0] === undefined ||
				res.data.recordsets[0][0] === undefined
			) {
				setWarning('Id not found, please adjust the url p=# param');
				return;
			}
			const arr = res.data.recordsets[0][0];
			setTitle(arr.ProjectName);
			setSubTitle(arr.PropertyName);
		});
	}, [paramId]);

	const props = { title, subTitle, paramId };

	return (
		<div className='App container py-3'>
			<Header />
			{warningMessagePreventingUse && (
				<Warning warning={warningMessagePreventingUse} />
			)}
			{!warningMessagePreventingUse && title && (
				<PdfGenerationForm {...props} />
			)}
			{!warningMessagePreventingUse && !title && <Loading />}
			<VersionTag />
		</div>
	);
};

export default App;
