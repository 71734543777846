import React, { useState, useEffect, useCallback } from 'react';
import { postFile, postPhoto } from '../services/api';
import FileUpload from './FileUpload';
import FilePreview from './FilePreview';

const accepted = section => {
	switch (section) {
		case 'Photos Before':
			return 'image/jpeg, image/png, image/jpg';
		case 'Photos During':
			return 'image/jpeg, image/png, image/jpg';
		case 'Photos After':
			return 'image/jpeg, image/png, image/jpg';
		default:
			return undefined;
	}
};

const MB = 1048576; //Bytes
const maxSize = 5 * MB;

const Section = props => {
	const { paramId, sectionId, sectionName } = props;
	const [previews, setPreviews] = useState({});
	const [error, setError] = useState({ message: '', timestamp: null });

	const handleError = message => {
		setError({ message, timestamp: new Date() });
	};

	const getImgUrls = useCallback(async () => {
		const { files } = await postFile(paramId, sectionId);
		files && setPreviews(files);
	}, [paramId, sectionId]);

	const uploadLocalPhotos = (acceptedFiles, rejectedFiles) => {
		//console.log(acceptedFiles, rejectedFiles)
		if (rejectedFiles.length > 0) {
			rejectedFiles.forEach(file => {
				file.errors.forEach(error => {
					if (error.code === 'file-too-large') {
						handleError('Error: File must be less than 5MB.');
					}
					if (error.code === 'file-invalid-type') {
						handleError('Error: Unsupported file type.');
					}
				});
			});
		}

		if (acceptedFiles.length > 0) {
			let form = new FormData();
			acceptedFiles.forEach(file => {
				form.append(file.name, file);
			});
			form.append('paramId', paramId);
			form.append('sectionId', sectionId);
			postPhoto(form)
				.then(res => {
					if (res.status !== 200) {
						handleError('Error: Failed to upload file');
					} else {
						handleError('');
						getImgUrls();
					}
				})
				.catch(error => handleError(`${error}`));
		}
	};

	useEffect(() => {
		getImgUrls();
	}, [getImgUrls]);

	useEffect(() => {
		if (error.message) {
			setTimeout(() => {
				setError({ message: '', timestamp: null });
			}, 5000);
		}
	}, [error]);

	const imgParentClass = previews.length > 4 ? 'col-6' : 'col-12';

	const fileName = (fullName, cutoff) =>
		fullName.length > cutoff
			? fullName.substring(0, cutoff - 3) + '...'
			: fullName;

	return (
		<div className={'col-4 card border border-dark'}>
			<div className='card-body'>
				<h5 className='card-title'>
					<u>{sectionName}</u>
				</h5>
				<FileUpload
					{...props}
					onDrop={uploadLocalPhotos}
					accept={accepted(sectionName)}
					maxSize={maxSize}
				/>
				{error.message && (
					<p className='card-text text-danger'>
						<strong>{error.message}</strong>
					</p>
				)}
				<div className='row mt-2'>
					{Object.keys(previews).map(index => {
						let preview = previews[index];
						const { name } = preview;
						let fileNameFixed = fileName(name, 14);
						return (
							<FilePreview
								{...props}
								key={name}
								preview={preview}
								imgParentClass={imgParentClass}
								getImgUrls={getImgUrls}
								fileNameFixed={fileNameFixed}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Section;
