const Logo = ({ width, height, color = '#003263' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 887 887'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M190 331L887 0V887H761V600.5L474 465.5L190 331Z' fill={color} />
			<path d='M305 710.5L645 545V887H305V710.5Z' fill={color} />
			<path d='M0 421L190 332V887H0V421Z' fill={color} />
		</svg>
	);
};

export default Logo;
