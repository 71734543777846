import React from 'react';

const Loading = () => {
	return (
		<div className='spinner-border lg-spinner mt-4' role='status'>
			<span className='visually-hidden'>Loading...</span>
		</div>
	);
};

export default Loading;
