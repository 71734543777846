const Warning = props => {
	const { warning } = props;
	return (
		<div className='alert alert-warning' role='alert'>
			{warning}
		</div>
	);
};

export default Warning;
